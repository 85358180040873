import React from 'react';
import { StyledEngineProvider } from '@mui/material';

import AuthProvider from 'AuthProvider';
import AuthDialog from 'AuthDialog';
import Router from 'Router';

import styles from './App.module.scss';

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <main className={styles.app}>
        <AuthProvider>
          <Router />
          <AuthDialog />
        </AuthProvider>
      </main>
    </StyledEngineProvider>
  );
};

export default App;
