import React, { useEffect, useState } from 'react';

import AuthContext from 'AuthContext';
import AuthService from 'AuthService';
import NhlMatchupsService from 'nhl/stats/NhlMatchupsService';
import NhlStatsService from 'nhl/stats/NhlStatsService';

const getSavedSecret = () => {
  const dttbSecret = window.localStorage.getItem('dttb.secret');
  return dttbSecret;
};

const setSavedSecret = (secret) => {
  window.localStorage.setItem('dttb.secret', secret);
};

const AuthProvider = ({ children = [] }) => {
  const [secret, setSecret] = useState(getSavedSecret());
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    if (!secret) return;
    setIsFetching(true);
    setFetchError(null);
    const loader = async () => {
      try {
        const authData = await AuthService.fetchAuth(secret);
        if (!authData?.dataUrl) throw new Error(`Bad dataUrl`, authData);
        setAuth(authData);
        setFetchError(null);
        NhlMatchupsService.setDataBaseUrl(authData.dataUrl);
        NhlStatsService.setDataBaseUrl(authData.dataUrl);
        setSavedSecret(secret);
      } catch (e) {
        setAuth(null);
        setFetchError(`Unable to auth`);
        console.error(`Unable to auth`, e);
      }

      setIsFetching(false);
    };
    loader();
    return () => {
      setAuth(null);
      setIsFetching(false);
      setFetchError(null);
    };
  }, [secret]);

  const contextValue = {
    fetchError,
    isFetching,
    auth,
    valid: auth?.dataUrl,
    setSecret,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {};

export default AuthProvider;
