const BASE_URL = `https://dttb-storage.s3.us-east-1.amazonaws.com/auth`;

class AuthService {
  static fetchAuth = async (secret) => {
    let authData = null;
    try {
      const url = new URL(`${BASE_URL}/${secret}.json`);
      const response = await fetch(url);
      authData = await response.json();
    } catch (e) {
      console.error(`Unable to fetch auth data`, e);
    }

    return authData;
  };
}

export default AuthService;
