import React, { useEffect, useState } from 'react';

import NhlStatsContext from './NhlStatsContext';
import NhlStatsService from './NhlStatsService';

// const cloudFrontToken = `dklxbc86gvygh`;
// const cloudFrontToken = `d1wignjlnasfdp`; //test

// const BASE_URL = `https://ipl-sportsdata2--test.s3.amazonaws.com`;
// const DATA_BASE_URL = `https://${cloudFrontToken}.cloudfront.net`;

const NhlStatsProvider = (props) => {
  const { children } = props;

  const [selectedSeason, setSelectedSeason] = useState(2024);
  const [data, setData] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    setFetchError(null);
    const loader = async () => {
      try {
        const data = await NhlStatsService.fetchStats(selectedSeason);
        setData(data);
      } catch (e) {
        setData({});
        setFetchError(`Unable to load data`);
        console.error(`Unable to load data`, e);
      }

      setIsFetching(false);
    };
    loader();
    return () => {
      setData({});
      setIsFetching(false);
    };
  }, [selectedSeason]);

  const contextValue = {
    fetchError,
    isFetching,
    data,
    selectedSeason,
    setSelectedSeason,
  };

  return <NhlStatsContext.Provider value={contextValue}>{children}</NhlStatsContext.Provider>;
};

NhlStatsProvider.propTypes = {};

export default NhlStatsProvider;
