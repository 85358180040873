import _ from 'lodash';

import Utils from 'Utils';

class NhlStatsService {
  static DATA_BASE_URL = null;
  static setDataBaseUrl = (dataBaseUrl) => {
    NhlStatsService.DATA_BASE_URL = dataBaseUrl;
  };

  static fetchStats = async (season) => {
    const data = {};
    const teamResults = {};
    const teamRosters = {};
    {
      const url = new URL(`${NhlStatsService.DATA_BASE_URL}/nhl/${season}/results/all.json`);
      url.searchParams.set('t', Utils.dateToDayString(new Date()));
      const response = await fetch(url);
      const { entries } = await response.json();
      let _entries = _.orderBy(_.values(entries), ['game.date', 'game.id'], ['desc', 'desc']);
      for (const entry of _entries) {
        const { id, teams, result, gameCenterLink, roster } = entry;
        const awayTeam = teams.away.code;
        const homeTeam = teams.home.code;
        _.set(teamResults, [awayTeam, String(id)], {
          goalsFor: result.away.ft.goalsFor,
          goalsAllowed: result.away.ft.goalsAgainst,
          overtime: result.overtime,
          shootout: result.shootout,
          gameCenterPath: gameCenterLink,
        });
        _.set(teamResults, [homeTeam, String(id)], {
          goalsFor: result.home.ft.goalsFor,
          goalsAllowed: result.home.ft.goalsAgainst,
          overtime: result.overtime,
          shootout: result.shootout,
          gameCenterPath: gameCenterLink,
        });

        _.set(teamRosters, [awayTeam, String(id)], roster?.away);
        _.set(teamRosters, [homeTeam, String(id)], roster?.home);
      }
      // console.log(teamRosters);
    }
    data.teamResults = teamResults;

    let teamData = {};
    {
      const url = new URL(`${NhlStatsService.DATA_BASE_URL}/nhl/${season}/empty-net/all-teams.json`);
      url.searchParams.set('t', Utils.dateToDayString(new Date()));
      const response = await fetch(url);
      const { league, teams, updatedAt } = await response.json();
      data.leagueEmptyNet = league;
      teamData = _.merge({}, teams);
      // augment EN data with some basic result info
      for (const team of _.values(teamData)) {
        for (const entry of _.values(team.entries)) {
          const { game, teamCode } = entry;
          entry.result = teamResults?.[teamCode]?.[game.id];
          entry.roster = teamRosters?.[teamCode]?.[game.id];
        }
      }
      data.updatedAt = new Date(updatedAt);
    }
    data.teams = teamData;

    let enAll = {};
    {
      const url = new URL(`${NhlStatsService.DATA_BASE_URL}/nhl/${season}/empty-net/all.json`);
      url.searchParams.set('t', Utils.dateToDayString(new Date()));
      const response = await fetch(url);
      const { entries } = await response.json();
      enAll = entries;
    }
    data.enAll = enAll;

    return data;
  };
}
export default NhlStatsService;
