import { createContext } from 'react';

const AuthContext = createContext({
  isFetching: false,
  fetchError: null,
  auth: null,
  valid: false,
  setSecret: () => {},
});

export default AuthContext;
