import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import AuthContext from 'AuthContext';

const AuthDialog = () => {
  const { valid, setSecret, isFetching, fetchError } = useContext(AuthContext);
  const [password, setPassword] = useState(``);
  if (valid) return null;

  const onSubmit = (e) => {
    e?.preventDefault();
    setSecret(password);
  };
  const onPasswordChange = (e) => {
    setPassword(e.currentTarget.value);
  };
  const onPasswordKeyDown = (e) => {
    if (e.key === 'Enter') onSubmit();
  };

  return (
    <Dialog open={true}>
      <DialogTitle>Login</DialogTitle>
      <DialogContent>
        <form autoComplete='off' data-lpignore={true} onSubmit={onSubmit}>
          <TextField
            autoFocus
            required
            margin='dense'
            id='search_secret'
            name='search_secret'
            label='Password...'
            type='password'
            fullWidth
            variant='standard'
            value={password}
            onChange={onPasswordChange}
            onKeyDown={onPasswordKeyDown}
            inputProps={{ autoComplete: 'off', 'data-lpignore': true }}
            disabled={isFetching}
          />
        </form>
        {fetchError ? `Bad password` : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onSubmit} disabled={isFetching}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuthDialog;
